
.s-main-page{
  margin-top: 20px;
}
.s-main .s-main-card{
  padding: 4px;
  background: #232323;
  border-radius: .3rem;
  color: white;
  margin: 10px;
  text-decoration: none;
}

.s-main .s-main-card:hover{
  opacity: 0.8;
  transition: all 0.07s ease-in-out;
}

.s-main .s-main-card-marketplace--container{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.s-main .flex{
  display: flex;
  align-items: center;
  justify-content: center;


}

.s-main .flex h1{
margin: 0px !important;
padding-left: 10px;

}

.s-main-card h1 {
  margin: 0px;
}
.s-main-card p {
  margin: 0px;
  padding-top: 10px;
}

.s-main .s-main__subtitle{
  margin: 0px !important;
}
.p-10{
  padding: 10px;
}



.s-main-card-marketplace{
  background: #232323;
  margin: 10px;
  border-radius: 10px;
  margin-top: 25px;
}

.s-main-card-marketplace h3{
  margin-left: 20px;
  color: white;
}

.s-main-dropdown{
  margin-right: 20px;
  display: flex;
  align-items: center;
  color: white;
  padding: 10px;
  background: #2d2d2d;
  margin-top: 10px;
  border-radius: 20px;
}

.s-main-dropdown svg{
  margin-left: 10px;
}


.s-main .s-main-single-card{
  padding: 4px;
  background: #2d2d2d;
  border-radius: .3rem;
  color: #9ca3af;
  
}

.s-main .s-main-card-container{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 2%;
  margin-bottom: 2%;
}

@media(max-width: 767px){
  .s-main .s-main-card-container{
    flex-direction: column;
  
  }

  .s-main-card-marketplace h3{
    margin-left: 5px;
    color: white;
    font-size: 12px;
  }

  .s-main-dropdown {
   font-size: 12px;
   padding-left: 10px;
    padding-right: 10px;
  }

  
  .s-main .flex h1{
    margin-left: 0px;
  }
  
}