.s-gallery{
margin-bottom: 5%;
}

.s-gallery__topSection{
display: flex;
}

.s-gallery__topSection .s-gallery__topSection__header{
    color: white;
    font-family: JetBrains Mono;
    margin: 0 auto;
    /* padding: 2em 3em; */
    text-align: left;
    margin-bottom: 12px;
}

.s-gallery__topSection .s-gallery__topSection__header a {
    color: #aed1eb;
}

.s-gallery__topSection .connect-btn-container{
    margin-left: auto;
}

.s-gallery .topmargin{
    margin-top: 100px;
    border: none;
}

.s-gallery__topSection .connect-page-btn{
    height: auto;
    
}
 .connect-animation > span{
    margin-left: auto;
    background: linear-gradient(to right, #4d4d4d 0, white 10%, #4d4d4d 20%);
background-position: 0;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
animation: shine 3s infinite linear;
animation-fill-mode: forwards;
-webkit-text-size-adjust: none;
}




  
@keyframes shine{
    0%{
        background-position: 0;
    }

  60%{
    background-position: 180px;
  }
 
  100%{
    background-position: 180px
  }
   
}

.displayDesktop{
    display: none !important;
}

.s-gallery__container{
    background: #2d2d2d;
    padding-top: 41px;
    padding-bottom: 10px;
    border-radius: 10px;
}
.s-gallery__images--background{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    width: 100%;
    
 }
 
@media(max-width: 768px){
    .s-gallery__container{
background: none;
        padding: 0px;
       
    }

    .s-gallery__topSection{
        flex-direction: column;
        }

        .s-gallery{
            margin-bottom: 0px;
            }

        .displayDesktop{
            display: flex !important;
        }

        .s-gallery__images--background{
        flex-direction: column;
           display: flex;
           align-items: center;
        }

        .s-gallery__images--background img {
           margin-bottom: 20px;
           margin-top: 0px;
        }
        
}



.s-gallery__images--background img {
    border-radius: 20px;
    transition: all 0.5ms ease-out;
    cursor: pointer;
    width: 200px;
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
}

.s-gallery__images--background img:hover{
    opacity: 0.9;

}

.s-gallery__btn{
    display: flex;
    justify-content: center;
    margin-top: 5%;
}

.s-gallery__btn--back{
   
 margin-right: 10px;
    cursor: pointer;
    text-decoration: none;
   
    color: white;
    justify-content: center;
    display: flex;
    align-items: center;
    border: 2px solid #fff;
    padding: 20px;
    background-origin: border-box;
    background-clip: padding-box, border-box;
    background-size: 200% 100%;
    background: transparent;
    background-size: 200% 100%;
    transition: all .5s ease-out;
}

.s-gallery__btn--next{
 
   
    border: 2px solid #fff;
    background-color: #fff;
    font-family: Virus_01;
    font-style: normal;
    font-weight: normal;
   
    text-transform: uppercase;
 padding: 20px;
    color: #000;
}

.s-gallery__btn--download{
  
   margin-left: 10px;
    border: 2px solid #fff;
    background-color: whitesmoke;

    font-style: normal;
    font-weight: normal;
   
    text-transform: uppercase;
 padding: 20px;
    color: #000;
}

.s-gallery__selected{
    border: 4px solid #e7e7e7;
    border-radius: 20px;
}


.s-gallery__images--metavaders{
    display: flex;
    align-items: center;
   justify-content: center;
}
.s-gallery__images--metavaders img {
    border-radius: 20px;
    transition: all 0.5ms ease-out;
    cursor: pointer;
    width: 120px;
    height: 120px;
    margin-right: 10px
}

.s-gallery__images--metavaders img:hover{
    opacity: 0.9;

}

.s-gallery__images--combined {
   background-repeat: no-repeat;
display: flex;
background-size: 500px;
    height: auto;
    position: relative;
    width: 100%;
    justify-content: center;
    align-items: center;
    background-position: center;
    margin-top: 20px;
    max-width: 600px;

    margin-left: auto;
    margin-right: auto;
}

.s-gallery__images--top{
    width: 100%;
    height: 100%;
}
.s-gallery__images--bottom{
    width: 120px;
    height: 120px;
   position: absolute;
   bottom: 0;
   z-index: 999;
}



.s-collab__armors{
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: grid;

    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    margin-left: auto;
    text-align: center;
    justify-content: center;
    grid-row-gap: 10px;
    margin-bottom: 10px;
}
@media(max-width: 1000px){
    .s-collab__armors{
        grid-template-columns: 1fr 1fr 1fr;
    }

}



.s-collab__armors img {
    width: 100px;
    /* margin-right: 10px; */

    cursor: pointer;
    border-radius: 10px;
    margin-left: auto;
    margin-right: auto;
}


@media(max-width: 768px){
    .s-gallery .topmargin{
        margin-top: 60px;
    }


    .s-gallery__images--metavaders{
        flex-direction: column;
    }

    .s-gallery__images--metavaders img{
width: 150px;
height: 150px;
margin-bottom: 20px;
    }

    .s-gallery__images--bottom{
        width: 50px;
        height: 50px;
    }

    .s-gallery__btn{
        flex-direction: column;
    }

    .s-gallery__btn button{
        margin-bottom: 10px;
        margin-left: 0px;
    }
}