@import url(https://fonts.googleapis.com/css2?family=JetBrains+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-width: 100vw;
  scroll-behavior: smooth;


  overflow-x: hidden;
}
html {
  scroll-behavior: smooth;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
@font-face {
  font-family: "pxlxxl";
  src: local("pxlxxl"), url(/static/media/pxlxxl.6ca6eec0.ttf) format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Virus_01";
  src: local("Virus_01"), url(/static/media/Virus_01.4d01599c.ttf) format("truetype");
  font-weight: normal;
}
button:hover {
  cursor: pointer;
}
.App {
  text-align: center;
  background-color: #1e1e1e;
  overflow-y: hidden;
 
  max-width: 100vw;
  min-height: 100vh;
}
.navbar {
  border-bottom: 2px solid white;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  /* overflow-x: hidden; */
  position: relative;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}
.navbar .connect-page-btn{
  height: auto !important
}
.divider {
  display: none;
  position: absolute;
  width: 100vw;
  left: 0;
  height: 1px;
  background: white;
  top: 130px;
  z-index: 99;
}
/* 
.navbar > a {
  width: 100%;
}
*/

.logo {
  width: 40px;
  height: 40px;
  /* padding: 40px 60px; */
}

.pro-sidebar-header > .pro-sidebar-title {
  padding: 32px !important
}
.menu {
  display: flex;
  position: absolute;
  right: 10px;
}

.new-section-text {
  font-family: JetBrains Mono;
  font-style: normal;
  font-weight: normal;
  font-size: 2.25em;
  line-height: 2em;
  text-align: left;
  color: #ffffff;
  margin-top: 1.5em;
  margin-left: 0px;
  margin-top: 0px;
}
.navbar-socialicon {
  width: 30px;
  height: 30px;
  padding: 15px;
  padding-bottom: 0px;
}
.topmargin{
  margin-left: 10px;
}
.topmargin h2{
 margin: 0px;
}
.topmargin p{
  font-size: 12px;
  color: lightgrey;
  font-style: italic;
  text-align: left;
  margin: 0px
}

.topmargin h5{
  font-size: 12px;
  color: #dd8f00;
  font-style: italic;
  text-align: center;
  margin-top: 10px;
}

.container-wrap {
  width: 100%;
  /* overflow-x: hidden; */
  /* border-top: 1px solid white; */
  justify-content: center;
  justify-items: center;
  margin: 0 auto;
}

.activate-ul {
  color: #fff;
  text-align: left;
  font-family: JetBrains Mono;
  margin: 0 auto;
  padding: 1em 2em 3em;
}
.activate-ul > li {
  line-height: 1.75em;
}

.connect-page-btn:active {
  background-color: rgba(255, 255, 255, 0.13);
  border-color: rgba(255, 255, 255, 0.507);
}
.connect-page-btn:hover {
  cursor: pointer;
}
.connect-page-btn {
  width: 100%;
  height: 100px;
  border: 2px solid #fff;
  background-color: transparent;

  font-family: JetBrains Mono;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  text-transform: uppercase;
  color: #fff;
}
.crew-members-cont,
.invasion-target-cont,
.agents-cont {
  width: 90%;
  background: #2d2d2d;
  margin: 25px auto;
  padding: 50px 0px;
}
.crew-members-info,
.invasion-target-info,
.agents-info {
  margin: 30px;
  margin-top: 0px;
  font-weight: 300;
  font-size: 20px;
  color: #fff;
  font-family: "JetBrains Mono";
}
.crew-members,
.invasion-targets,
.agents {
  display: flex;
  padding: 0px 30px;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;
}
.crew-member,
.invasion-target,
.agent {
  width: 110px;
  margin: 5px;
}
.crew-member > img,
.invasion-target > img,
.agent > img {
  width: 100%;
}

.drop-shadow {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.activate-page-btn {
  width: 90%;
  height: 100px;
  border: 2px solid #fff;
  background-color: #fff;

  font-family: Virus_01;
  font-style: normal;
  font-weight: normal;
  font-size: 32px;
  text-transform: uppercase;
  line-height: 63px;

  color: #000;
}

.footer {
  margin: 80px 0px 40px 0px;
}

.crew-member img{
  border-radius: 20px;
    transition: all 0.5ms ease-out;
    cursor: pointer;

    margin-right: 10px;
}

.selected > img {
  border: 4px solid #e7e7e7;
}

@media (max-width: 1300px) {
  .navbar-socialicon {
    width: 30px;
    height: 30px;
    padding: 6px;
  }
}

@media (max-width: 768px) {
  .pro-sidebar-header > .pro-sidebar-title {
    padding: 34px !important; 
  }
  .navbar{
    overflow-x: unset;
    /* margin-top: 45px; */
    width: 100%;
    padding-bottom: 15px;
  }

  .connect-page-btn {
    font-size: 12px;
  }

  #status{
    font-size: 12px
  }

  .logo {
    padding: 40px;
  }
  .container-wrap {
    width: 100%;
  }
  .topmargin {
     width: 170px;
    /* height: 0px;
    margin-top: -25px;
    border: 0;  */
  }

  .navbar-socialicon {
    width: 25px;
    height: 25px;
    padding: 6px;
  }
  .new-section-text {
    font-size: 2em;
    margin: 1em 1.5em;
    line-height: 1.5em;
  }
  .new-section-text {
    text-align: center;
  }

 
}

.disabled{
  background: grey !important;
  border: 2px solid grey !important;
}

@media (max-width: 480px) {
  .logo {
    padding: 10px;
    height: 30px;
    width: 30px;
  }
  .navbar-socialicon {
    width: 20px;
    height: 20px;
    padding: 5px;
  }
  .activate-ul > li {
    font-size: 0.9em;
  }
}


.s-button{
  box-shadow: 0px 0px 0px 2px #eeeeee;
  background-image: linear-gradient(40deg, #e8e8e8 20%,  #d4d2d2 51%, #bdbaba 90%);
  background-position: left center;
  transition: 0.5s;
  background-size: 200% auto;
}

.s-button:hover{
  background-position: right center;
}

.app {
  height: 100vh;
  /* height: 100%; */
  display: flex;
  position: relative;
}




  .s-main {
    padding: 24px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
  }

  .s-main-page .crew-members-cont{
    background: #232323;
    margin: 0px;
    padding: 20px;
    border-radius: .3rem;
    box-shadow: none;
  }
  .s-main-page .s-gallery__images--background{
    padding-bottom: 25px;
  }

  @media(max-width: 767px){
    .s-main{
      padding: 10px;
    }
  }
 

  .icon-spin {
    -webkit-animation: icon-spin 2s infinite linear;
            animation: icon-spin 2s infinite linear;
  }
  
  @-webkit-keyframes icon-spin {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(359deg);
              transform: rotate(359deg);
    }
  }
  
  @keyframes icon-spin {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(359deg);
              transform: rotate(359deg);
    }
  }


.s-link{
    padding: 10px;
    cursor: pointer;
    text-decoration: none;
   max-width: 120px;
   color: white;
  justify-content: center;
   display: flex;
   align-items: center;
   border: 2px solid #fff;
    border-radius: 80px;
   
    background-origin: border-box;
    background-clip: padding-box, border-box;
    background-size: 200% 100%;
    background: transparent;
    background-size: 200% 100%;
    transition: all .5s ease-out;
}


.s-link:hover {
   background: whitesmoke;
  }

  .s-link:hover {
    color: black;
  }





.s-link--activate{
    font-size: 48px;
    text-decoration: underline;
    cursor: pointer;
    line-height: 34px;
    margin: 0 20px;
    scroll-behavior: smooth;
    font-family: pxlxxl;
    font-style: normal;
    font-weight: 400;
    color: #fff;
    transition: all .2s ease-out;
}

.s-link--activate:hover{
    opacity: 0.7;
}

.s-icon {
    display: none;
    color: white;
    font-size: 35px;
}

.s-drawer{
    transition: all 0.5s ease-out;
    outline: 0;
    display: flex;
    flex-direction: column;
    position: absolute;
    max-width: calc(100% - 32px);
    min-width: 16px;
    max-height: calc(100% - 32px);
    min-height: 155px;
  z-index: 9999999;

    top: 0px;
    left: 16px;
    border-radius: 4px;
    background-color: #1e1e1e;
    border: 1px solid #fff;
    box-shadow: 0px 5px 5px -3px rgba(0, 0, 0 , 0.20), 0px 8px 10px 1px rgba(0 ,0, 0 ,0.14), 0px 3px 14px 2px rgba(0, 0, 0,  0.12);

}

.s-drawer a {
    width: auto;
    overflow: hidden;
    font-size: 30px;
    box-sizing: border-box;
    min-height: 48px;
    font-family: pxlxxl;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    padding-top: 6px;
    white-space: nowrap;
    letter-spacing: 0.00938em;
    padding-bottom: 6px;
}

.s-drawer .s-icon{
    font-size: 18px;
    position: absolute;
    right: 0;
    padding: 5px;
}

.s-notification{

    display: none;

    }

    .s-navbar__switchBtn {
        display: flex;
        align-items: center;
    }
    .s-navbar__switchBtn p{
       color: white;
       font-size: 48px;
      
       cursor: pointer;
       line-height: 34px;
       margin: 0 20px;
       scroll-behavior: smooth;
       font-family: pxlxxl;
       font-style: normal;
       font-weight: 400;
       color: #fff;
       transition: all .2s ease-out;
    }

@media(max-width: 768px){
    .hidden{
        display: none
    }
    .s-link--activate{
        line-height: 0px;
        margin-top: 10px;
        font-size: 25px;
    }

    .s-navbar__switchBtn{
        display: none;
    }

    .navbar .s-link{
        padding: 5px;
        max-width: 66px;
        margin-right: 30px;
        margin: 0;
    margin-right: -10px;
    }

    .s-icon{
        display: flex
    }

    .s-notification{
        position: absolute;
        top: 34px;
        border-radius: 4px;
        background-color: #5f5f5f;
        border: 1px solid #fff;
        box-shadow: 0px 5px 5px -3px rgba(0, 0, 0 , 0.20), 0px 8px 10px 1px rgba(0 ,0, 0 ,0.14), 0px 3px 14px 2px rgba(0, 0, 0,  0.12);
        padding: 10px;
        color: white;
        font-size: 14px;
        display: flex;
        z-index: 999999999;
        margin-top: -35px;
    }
}


.invasion-target {
    min-height: 100px;
    min-width: 100px;
    display: flex;
    vertical-align: middle;
}
.invasion-target img {
    width: 100%;
    height: 100%;
}



.s-home__btn--first{
   
    background: transparent;
    background-size: 200% 100%;
    transition: all .5s ease-out;
}

.s-home__btn--first:hover {
   background: whitesmoke;
  }

  .s-home__btn--first:hover span{
    color: black;
  }
  .s-home__btn--first:hover{
    color: black;
  }

  .s-home__btn--second{
    background: whitesmoke;
    background-size: 200% 100%;
    transition: all .5s ease-out;
}

.s-home__btn--second:hover {
    background-position: left bottom;
    background: #181818;
  }

  .s-home__btn--second:hover span{
    color: white;
  }

   .s-home__label{
    text-align: left;
    color: #c7c7c7;
    font-size: 18px;
    font-style: italic;
  }

  @media(max-width: 768px){
    .s-home__btn--first{
        height: auto;

    }


  }
.modal {
  font-size: 12px;
}
.popup-content {
  background: #282a36;
}
.modal > .header {
  /* width: 100%; */
  border-bottom: 1px solid gray;
  font-size: 18px;
  text-align: center;
  padding: 5px;
  color: #fff;
}

.modal > .content {
  /* width: 100%; */
  padding: 10px 5px;
  color: #fff;
  font-size: 14px;
  text-align: center;
}
.modal > .actions {
  /* width: 100%; */
  padding: 10px 5px;
  margin: auto;
  text-align: center;
}

.modal > .actions button{

    background: whitesmoke;
    background-size: 200% 100%;
    transition: all .5s ease-out;
    width: auto;
    height: 35px;
    border: 2px solid #fff;
    background-color: #fff;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    text-transform: uppercase;
  border-radius: 10px;
    color: #000;
}


.modal > .actions button:hover {
  background-position: left bottom;
  background: #181818;
  color: white;
}

.modal > .actions button:hover span{
  color: white;
}

.modal > .close {
  cursor: pointer;
  position: absolute;
  display: block;
  padding: 2px 5px;
  line-height: 20px;
  right: -10px;
  top: -10px;
  font-size: 24px;
  background: #ffffff;
  border-radius: 18px;
  border: 1px solid #cfcece;
}
/* .example-warper .button,
.popup-content .button {
  background-color: #fff;
  border: 3px dashed #25c2a0;
  border-radius: 8px;
  color: #000;
  cursor: pointer;
  display: inline-block;
  font-family: Roboto, Arial, sans-serif;
  font-size: 14px;
  letter-spacing: 1.5px;
  line-height: 2em;
  margin: 10px 15px;
  outline: 0;
  padding: 0 30px;
  position: relative;
  text-transform: uppercase;
  transition: 0.3s;
} */

.modal > .content {
  
}
.activation_text {
  padding: 5px 0;
}
.activation_image {
  width: 100%;
  max-width: 200px;
  border-radius: 20px;
}










/* body {
  margin: 0;
  height: 100vh;
  color: #353535;
  font-family: 'Roboto', sans-serif;
} */

#root {
  height: 100%;
}



.app {
  height: 100%;
  display: flex;
  position: relative;
  height: 100vh;
}
  .btn-toggle {
    cursor: pointer;
    width: 35px;
    height: 35px;
    background: #353535;
    color: #fff;
    text-align: center;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    display: none;
  }

  .sidebar-btn-wrapper .s-link{
  margin-left: auto;
  margin-right: auto;
  }

  .sidebar-btn-wrapper   .sidebar-btn {
    transition: width 0.3s;
    width: 150px;
    padding: 1px 15px;
    border-radius: 40px;
    background: rgba(255, 255, 255, 0.05);
    color: #adadad;
    text-decoration: none;
    margin: 0 auto;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-overflow: ellipsis;
    overflow: hidden;
  
  }

  .sidebar-btn-wrapper .sidebar-btn   span {
    margin-left: 5px;
    font-size: 13px;
  }
  .sidebar-btn-wrapper .sidebar-btn   span:hover {
    color: #dee2ec;
  }




  
  .collapsed .sidebar-btn{
  
      display: inline-block;
      line-height: 40px;
      padding: 0;
    
  }

  main header  {
    margin-bottom: 40px;
    border-bottom: 1px solid #efefef;
  
  }

  main header   h1 {
    display: flex;
    align-items: center;
    -webkit-transform: translateX(-20px);
            transform: translateX(-20px);
  }
  .social-bagdes {
    margin-top: 10px;
    
  }

  .social-badges img{

        margin: 5px;
      
  }

  .block {
    display: flex;
    margin-bottom: 24px;
    font-size: 14px;
    color: #545454;
   
  }

  .block .span{
   
        margin-left: 10px;
      
  }
 

 

  .sidebar-btn-wrapper .sidebar-btn span {
    
      margin-left: 0;
      margin-right: 5px;
    
  }

.badge {
  padding: 3px 6px;
  font-size: 9px;
  letter-spacing: 1px;
  border-radius: 15px;
 
}

.red {
    color: #ffffff;
    background: #d63030;
  }

  
  .gray {
    color: #ffffff;
    background: #777777;
  }
  .yellow {
    color: #000000;
    background: #ffd331;
  }

  .pro-sidebar-inner{
      background: #232323 !important;
      height: 100%;
  }

  .pro-sidebar .footer{
margin: 0;
  }
 .s-icon-times{
  position: absolute;
  top: 0;
    right: 8px;
    margin-top: 10px;
    /* margin-right: 10px; */
    background: white;
    border-radius: 100px;
    display: flex;
    padding: 5px;

}

.pro-sidebar{
 z-index: 9 !important;
}

  @media(max-width: 768px){
    .pro-sidebar{
      position: absolute;
    }
  }

  .s-sidebar .pro-sidebar-inner{
    background: linear-gradient( 
      277.85deg, rgba(41, 0, 78,1) -0.21%, rgba(0, 36, 84,1) 91.73%) !important;
  }

  .s-sidebar__bottomBtn{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1px;
    background: lightblue;
    text-decoration: none;
    color: white;
    /* width: 80%; */
    max-width: 180px;
    border: 3px solid lightblue;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    border-radius: 25px;
    background: linear-gradient(
277.85deg, rgb(10, 23, 39) -0.21%, rgb(0, 36, 84) 91.73%);
  }
.s-gallery{
margin-bottom: 5%;
}

.s-gallery__topSection{
display: flex;
}

.s-gallery__topSection .s-gallery__topSection__header{
    color: white;
    font-family: JetBrains Mono;
    margin: 0 auto;
    /* padding: 2em 3em; */
    text-align: left;
    margin-bottom: 12px;
}

.s-gallery__topSection .s-gallery__topSection__header a {
    color: #aed1eb;
}

.s-gallery__topSection .connect-btn-container{
    margin-left: auto;
}

.s-gallery .topmargin{
    margin-top: 100px;
    border: none;
}

.s-gallery__topSection .connect-page-btn{
    height: auto;
    
}
 .connect-animation > span{
    margin-left: auto;
    background: linear-gradient(to right, #4d4d4d 0, white 10%, #4d4d4d 20%);
background-position: 0;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
-webkit-animation: shine 3s infinite linear;
        animation: shine 3s infinite linear;
-webkit-animation-fill-mode: forwards;
        animation-fill-mode: forwards;
-webkit-text-size-adjust: none;
}




  
@-webkit-keyframes shine{
    0%{
        background-position: 0;
    }

  60%{
    background-position: 180px;
  }
 
  100%{
    background-position: 180px
  }
   
}




  
@keyframes shine{
    0%{
        background-position: 0;
    }

  60%{
    background-position: 180px;
  }
 
  100%{
    background-position: 180px
  }
   
}

.displayDesktop{
    display: none !important;
}

.s-gallery__container{
    background: #2d2d2d;
    padding-top: 41px;
    padding-bottom: 10px;
    border-radius: 10px;
}
.s-gallery__images--background{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    width: 100%;
    
 }
 
@media(max-width: 768px){
    .s-gallery__container{
background: none;
        padding: 0px;
       
    }

    .s-gallery__topSection{
        flex-direction: column;
        }

        .s-gallery{
            margin-bottom: 0px;
            }

        .displayDesktop{
            display: flex !important;
        }

        .s-gallery__images--background{
        flex-direction: column;
           display: flex;
           align-items: center;
        }

        .s-gallery__images--background img {
           margin-bottom: 20px;
           margin-top: 0px;
        }
        
}



.s-gallery__images--background img {
    border-radius: 20px;
    transition: all 0.5ms ease-out;
    cursor: pointer;
    width: 200px;
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
}

.s-gallery__images--background img:hover{
    opacity: 0.9;

}

.s-gallery__btn{
    display: flex;
    justify-content: center;
    margin-top: 5%;
}

.s-gallery__btn--back{
   
 margin-right: 10px;
    cursor: pointer;
    text-decoration: none;
   
    color: white;
    justify-content: center;
    display: flex;
    align-items: center;
    border: 2px solid #fff;
    padding: 20px;
    background-origin: border-box;
    background-clip: padding-box, border-box;
    background-size: 200% 100%;
    background: transparent;
    background-size: 200% 100%;
    transition: all .5s ease-out;
}

.s-gallery__btn--next{
 
   
    border: 2px solid #fff;
    background-color: #fff;
    font-family: Virus_01;
    font-style: normal;
    font-weight: normal;
   
    text-transform: uppercase;
 padding: 20px;
    color: #000;
}

.s-gallery__btn--download{
  
   margin-left: 10px;
    border: 2px solid #fff;
    background-color: whitesmoke;

    font-style: normal;
    font-weight: normal;
   
    text-transform: uppercase;
 padding: 20px;
    color: #000;
}

.s-gallery__selected{
    border: 4px solid #e7e7e7;
    border-radius: 20px;
}


.s-gallery__images--metavaders{
    display: flex;
    align-items: center;
   justify-content: center;
}
.s-gallery__images--metavaders img {
    border-radius: 20px;
    transition: all 0.5ms ease-out;
    cursor: pointer;
    width: 120px;
    height: 120px;
    margin-right: 10px
}

.s-gallery__images--metavaders img:hover{
    opacity: 0.9;

}

.s-gallery__images--combined {
   background-repeat: no-repeat;
display: flex;
background-size: 500px;
    height: auto;
    position: relative;
    width: 100%;
    justify-content: center;
    align-items: center;
    background-position: center;
    margin-top: 20px;
    max-width: 600px;

    margin-left: auto;
    margin-right: auto;
}

.s-gallery__images--top{
    width: 100%;
    height: 100%;
}
.s-gallery__images--bottom{
    width: 120px;
    height: 120px;
   position: absolute;
   bottom: 0;
   z-index: 999;
}



.s-collab__armors{
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: grid;

    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    margin-left: auto;
    text-align: center;
    justify-content: center;
    grid-row-gap: 10px;
    margin-bottom: 10px;
}
@media(max-width: 1000px){
    .s-collab__armors{
        grid-template-columns: 1fr 1fr 1fr;
    }

}



.s-collab__armors img {
    width: 100px;
    /* margin-right: 10px; */

    cursor: pointer;
    border-radius: 10px;
    margin-left: auto;
    margin-right: auto;
}


@media(max-width: 768px){
    .s-gallery .topmargin{
        margin-top: 60px;
    }


    .s-gallery__images--metavaders{
        flex-direction: column;
    }

    .s-gallery__images--metavaders img{
width: 150px;
height: 150px;
margin-bottom: 20px;
    }

    .s-gallery__images--bottom{
        width: 50px;
        height: 50px;
    }

    .s-gallery__btn{
        flex-direction: column;
    }

    .s-gallery__btn button{
        margin-bottom: 10px;
        margin-left: 0px;
    }
}

.s-main-page{
  margin-top: 20px;
}
.s-main .s-main-card{
  padding: 4px;
  background: #232323;
  border-radius: .3rem;
  color: white;
  margin: 10px;
  text-decoration: none;
}

.s-main .s-main-card:hover{
  opacity: 0.8;
  transition: all 0.07s ease-in-out;
}

.s-main .s-main-card-marketplace--container{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.s-main .flex{
  display: flex;
  align-items: center;
  justify-content: center;


}

.s-main .flex h1{
margin: 0px !important;
padding-left: 10px;

}

.s-main-card h1 {
  margin: 0px;
}
.s-main-card p {
  margin: 0px;
  padding-top: 10px;
}

.s-main .s-main__subtitle{
  margin: 0px !important;
}
.p-10{
  padding: 10px;
}



.s-main-card-marketplace{
  background: #232323;
  margin: 10px;
  border-radius: 10px;
  margin-top: 25px;
}

.s-main-card-marketplace h3{
  margin-left: 20px;
  color: white;
}

.s-main-dropdown{
  margin-right: 20px;
  display: flex;
  align-items: center;
  color: white;
  padding: 10px;
  background: #2d2d2d;
  margin-top: 10px;
  border-radius: 20px;
}

.s-main-dropdown svg{
  margin-left: 10px;
}


.s-main .s-main-single-card{
  padding: 4px;
  background: #2d2d2d;
  border-radius: .3rem;
  color: #9ca3af;
  
}

.s-main .s-main-card-container{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 2%;
  margin-bottom: 2%;
}

@media(max-width: 767px){
  .s-main .s-main-card-container{
    flex-direction: column;
  
  }

  .s-main-card-marketplace h3{
    margin-left: 5px;
    color: white;
    font-size: 12px;
  }

  .s-main-dropdown {
   font-size: 12px;
   padding-left: 10px;
    padding-right: 10px;
  }

  
  .s-main .flex h1{
    margin-left: 0px;
  }
  
}
.invasion-target {
    min-height: 100px;
    min-width: 100px;
    display: flex;
    vertical-align: middle;
}
.invasion-target img {
    width: 100%;
    height: 100%;
    border-radius: 20px;
    cursor: pointer;
}


.s-starship {
  background: linear-gradient( 
    277.85deg, rgb(10, 23, 39) -0.21%, rgb(0, 36, 84) 91.73%) !important;
}
.s-home__btn--first{
   
    background: linear-gradient(to right, whitesmoke 50%, transparent 50%);
    background-size: 200% 100%;
    background-position: right bottom;
    transition: all .5s ease-out;
}

.s-home__btn--first:hover {
    background-position: left bottom;
  }

  .s-home__btn--first:hover span{
    color: black;
  }
  .s-home__btn--first:hover{
    color: black;
  }

  .s-home__btn--second{
    background: linear-gradient(to right, #181818 50%, whitesmoke 50%);
    background-size: 200% 100%;
    background-position: right bottom;
    transition: all .5s ease-out;
}

.s-home__btn--second:hover {
    background-position: left bottom;

  }

  .s-home__btn--second:hover span{
    color: white;
  }

  .s-home__btn--more{
    text-decoration: none;
    color: black;
    /* width: 99%; */
    max-width: 200px;
    padding: 10px;
    /* height: 100px; */
    border: 2px solid #fff;
    background-color: #fff;
    font-family: Virus_01;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    text-transform: uppercase;
    margin-top: 10px;
    border-radius: 5px;
  }

  .s-home__opensea{
    flex-direction: column !important;
  }
  .s-home__opensea img{
    max-width: 600px;
  }
  .s-home__opensea img:hover{
   
      cursor: pointer;
      -webkit-transform: scale(1.1, 1.1);
              transform: scale(1.1, 1.1);
    
    
    
  }

  
  .s-home__opensea img:hover .overlay {
    height: 10%;
  }

  .s-home__opensea img{
    transition: all 0.9s ease-in-out;
    overflow: hidden;
  }
 
  @media(max-width: 768px){
    .s-home__btn--first{
        height: auto;
    }
  }
  

  /* REACT TOGGLE STYLES*/
  .react-toggle {
    touch-action: pan-x;
  
    display: inline-block;
    position: relative;
    cursor: pointer;
    background-color: transparent;
    border: 0;
    padding: 0;
  
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
  
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    -webkit-tap-highlight-color: transparent;
  }
  
  .react-toggle-screenreader-only {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  
  .react-toggle--disabled {
    cursor: not-allowed;
    opacity: 0.5;
    transition: opacity 0.25s;
  }
  
  .react-toggle-track {
    width: 50px;
    height: 24px;
    padding: 0;
    border-radius: 30px;
    background-color: #4D4D4D;
    transition: all 0.2s ease;
  }
  
  .react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
    background-color: #000000;
  }
  
  .react-toggle--checked .react-toggle-track {
    background-color: #19AB27;
  }
  
  .react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
    background-color: #128D15;
  }
  
  .react-toggle-track-check {
    position: absolute;
    width: 14px;
    height: 10px;
    top: 0px;
    bottom: 0px;
    margin-top: auto;
    margin-bottom: auto;
    line-height: 0;
    left: 8px;
    opacity: 0;
    transition: opacity 0.25s ease;
  }
  
  .react-toggle--checked .react-toggle-track-check {
    opacity: 1;
    transition: opacity 0.25s ease;
  }
  
  .react-toggle-track-x {
    position: absolute;
    width: 10px;
    height: 10px;
    top: 0px;
    bottom: 0px;
    margin-top: auto;
    margin-bottom: auto;
    line-height: 0;
    right: 10px;
    opacity: 1;
    transition: opacity 0.25s ease;
  }
  
  .react-toggle--checked .react-toggle-track-x {
    opacity: 0;
  }
  
  .react-toggle-thumb {
    transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    position: absolute;
    top: 1px;
    left: 1px;
    width: 22px;
    height: 22px;
    border: 1px solid #4D4D4D;
    border-radius: 50%;
    background-color: #FAFAFA;
    box-sizing: border-box;
    transition: all 0.25s ease;
  }
  
  .react-toggle--checked .react-toggle-thumb {
    left: 27px;
    border-color: #19AB27;
  }
  
  .react-toggle--focus .react-toggle-thumb {
    box-shadow: 0px 0px 2px 3px #0099E0;
  }
  
  .react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
    box-shadow: 0px 0px 5px 5px #0099E0;
  }

  .s-home__toggle{
    margin-top: 60px;
    text-align: left;
    margin-bottom: 20px;
  display: flex;
  align-items: center;
  }

  .s-home__toggle .s-home__toggle__tooltip svg{
    color: white;
    margin-left: 5px;
  }

  .s-main {
    padding: 24px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
  }

  .s-main-page .crew-members-cont{
    background: #232323;
    margin: 0px;
    padding: 20px;
    border-radius: .3rem;
    box-shadow: none;
  }
  .s-main-page .s-gallery__images--background{
    padding-bottom: 25px;
  }

  @media(max-width: 767px){
    .s-main{
      padding: 10px;
    }
  }
 

  .icon-spin {
    -webkit-animation: icon-spin 2s infinite linear;
            animation: icon-spin 2s infinite linear;
  }
  
  @-webkit-keyframes icon-spin {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(359deg);
              transform: rotate(359deg);
    }
  }
  
  @keyframes icon-spin {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(359deg);
              transform: rotate(359deg);
    }
  }
.absolute {
    position: absolute;
}

