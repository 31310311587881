body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-width: 100vw;
  scroll-behavior: smooth;


  overflow-x: hidden;
}
html {
  scroll-behavior: smooth;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
@import url("https://fonts.googleapis.com/css2?family=JetBrains+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
@font-face {
  font-family: "pxlxxl";
  src: local("pxlxxl"), url("./fonts/pxlxxl.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Virus_01";
  src: local("Virus_01"), url("./fonts/Virus_01.ttf") format("truetype");
  font-weight: normal;
}
button:hover {
  cursor: pointer;
}
.App {
  text-align: center;
  background-color: #1e1e1e;
  overflow-y: hidden;
 
  max-width: 100vw;
  min-height: 100vh;
}
.navbar {
  border-bottom: 2px solid white;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  /* overflow-x: hidden; */
  position: relative;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}
.navbar .connect-page-btn{
  height: auto !important
}
.divider {
  display: none;
  position: absolute;
  width: 100vw;
  left: 0;
  height: 1px;
  background: white;
  top: 130px;
  z-index: 99;
}
/* 
.navbar > a {
  width: 100%;
}
*/

.logo {
  width: 40px;
  height: 40px;
  /* padding: 40px 60px; */
}

.pro-sidebar-header > .pro-sidebar-title {
  padding: 32px !important
}
.menu {
  display: flex;
  position: absolute;
  right: 10px;
}

.new-section-text {
  font-family: JetBrains Mono;
  font-style: normal;
  font-weight: normal;
  font-size: 2.25em;
  line-height: 2em;
  text-align: left;
  color: #ffffff;
  margin-top: 1.5em;
  margin-left: 0px;
  margin-top: 0px;
}
.navbar-socialicon {
  width: 30px;
  height: 30px;
  padding: 15px;
  padding-bottom: 0px;
}
.topmargin{
  margin-left: 10px;
}
.topmargin h2{
 margin: 0px;
}
.topmargin p{
  font-size: 12px;
  color: lightgrey;
  font-style: italic;
  text-align: left;
  margin: 0px
}

.topmargin h5{
  font-size: 12px;
  color: #dd8f00;
  font-style: italic;
  text-align: center;
  margin-top: 10px;
}

.container-wrap {
  width: 100%;
  /* overflow-x: hidden; */
  /* border-top: 1px solid white; */
  justify-content: center;
  justify-items: center;
  margin: 0 auto;
}

.activate-ul {
  color: #fff;
  text-align: left;
  font-family: JetBrains Mono;
  margin: 0 auto;
  padding: 1em 2em 3em;
}
.activate-ul > li {
  line-height: 1.75em;
}

.connect-page-btn:active {
  background-color: rgba(255, 255, 255, 0.13);
  border-color: rgba(255, 255, 255, 0.507);
}
.connect-page-btn:hover {
  cursor: pointer;
}
.connect-page-btn {
  width: 100%;
  height: 100px;
  border: 2px solid #fff;
  background-color: transparent;

  font-family: JetBrains Mono;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  text-transform: uppercase;
  color: #fff;
}
.crew-members-cont,
.invasion-target-cont,
.agents-cont {
  width: 90%;
  background: #2d2d2d;
  margin: 25px auto;
  padding: 50px 0px;
}
.crew-members-info,
.invasion-target-info,
.agents-info {
  margin: 30px;
  margin-top: 0px;
  font-weight: 300;
  font-size: 20px;
  color: #fff;
  font-family: "JetBrains Mono";
}
.crew-members,
.invasion-targets,
.agents {
  display: flex;
  padding: 0px 30px;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;
}
.crew-member,
.invasion-target,
.agent {
  width: 110px;
  margin: 5px;
}
.crew-member > img,
.invasion-target > img,
.agent > img {
  width: 100%;
}

.drop-shadow {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.activate-page-btn {
  width: 90%;
  height: 100px;
  border: 2px solid #fff;
  background-color: #fff;

  font-family: Virus_01;
  font-style: normal;
  font-weight: normal;
  font-size: 32px;
  text-transform: uppercase;
  line-height: 63px;

  color: #000;
}

.footer {
  margin: 80px 0px 40px 0px;
}

.crew-member img{
  border-radius: 20px;
    transition: all 0.5ms ease-out;
    cursor: pointer;

    margin-right: 10px;
}

.selected > img {
  border: 4px solid #e7e7e7;
}

@media (max-width: 1300px) {
  .navbar-socialicon {
    width: 30px;
    height: 30px;
    padding: 6px;
  }
}

@media (max-width: 768px) {
  .pro-sidebar-header > .pro-sidebar-title {
    padding: 34px !important; 
  }
  .navbar{
    overflow-x: unset;
    /* margin-top: 45px; */
    width: 100%;
    padding-bottom: 15px;
  }

  .connect-page-btn {
    font-size: 12px;
  }

  #status{
    font-size: 12px
  }

  .logo {
    padding: 40px;
  }
  .container-wrap {
    width: 100%;
  }
  .topmargin {
     width: 170px;
    /* height: 0px;
    margin-top: -25px;
    border: 0;  */
  }

  .navbar-socialicon {
    width: 25px;
    height: 25px;
    padding: 6px;
  }
  .new-section-text {
    font-size: 2em;
    margin: 1em 1.5em;
    line-height: 1.5em;
  }
  .new-section-text {
    text-align: center;
  }

 
}

.disabled{
  background: grey !important;
  border: 2px solid grey !important;
}

@media (max-width: 480px) {
  .logo {
    padding: 10px;
    height: 30px;
    width: 30px;
  }
  .navbar-socialicon {
    width: 20px;
    height: 20px;
    padding: 5px;
  }
  .activate-ul > li {
    font-size: 0.9em;
  }
}


.s-button{
  box-shadow: 0px 0px 0px 2px #eeeeee;
  background-image: linear-gradient(40deg, #e8e8e8 20%,  #d4d2d2 51%, #bdbaba 90%);
  background-position: left center;
  transition: 0.5s;
  background-size: 200% auto;
}

.s-button:hover{
  background-position: right center;
}

.app {
  height: 100vh;
  /* height: 100%; */
  display: flex;
  position: relative;
}




  .s-main {
    padding: 24px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
  }

  .s-main-page .crew-members-cont{
    background: #232323;
    margin: 0px;
    padding: 20px;
    border-radius: .3rem;
    box-shadow: none;
  }
  .s-main-page .s-gallery__images--background{
    padding-bottom: 25px;
  }

  @media(max-width: 767px){
    .s-main{
      padding: 10px;
    }
  }
 

  .icon-spin {
    -webkit-animation: icon-spin 2s infinite linear;
            animation: icon-spin 2s infinite linear;
  }
  
  @-webkit-keyframes icon-spin {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(359deg);
              transform: rotate(359deg);
    }
  }
  
  @keyframes icon-spin {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(359deg);
              transform: rotate(359deg);
    }
  }