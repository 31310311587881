



/* body {
  margin: 0;
  height: 100vh;
  color: #353535;
  font-family: 'Roboto', sans-serif;
} */

#root {
  height: 100%;
}



.app {
  height: 100%;
  display: flex;
  position: relative;
  height: 100vh;
}
  .btn-toggle {
    cursor: pointer;
    width: 35px;
    height: 35px;
    background: #353535;
    color: #fff;
    text-align: center;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    display: none;
  }

  .sidebar-btn-wrapper .s-link{
  margin-left: auto;
  margin-right: auto;
  }

  .sidebar-btn-wrapper   .sidebar-btn {
    transition: width 0.3s;
    width: 150px;
    padding: 1px 15px;
    border-radius: 40px;
    background: rgba(255, 255, 255, 0.05);
    color: #adadad;
    text-decoration: none;
    margin: 0 auto;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-overflow: ellipsis;
    overflow: hidden;
  
  }

  .sidebar-btn-wrapper .sidebar-btn   span {
    margin-left: 5px;
    font-size: 13px;
  }
  .sidebar-btn-wrapper .sidebar-btn   span:hover {
    color: #dee2ec;
  }




  
  .collapsed .sidebar-btn{
  
      display: inline-block;
      line-height: 40px;
      padding: 0;
    
  }

  main header  {
    margin-bottom: 40px;
    border-bottom: 1px solid #efefef;
  
  }

  main header   h1 {
    display: flex;
    align-items: center;
    transform: translateX(-20px);
  }
  .social-bagdes {
    margin-top: 10px;
    
  }

  .social-badges img{

        margin: 5px;
      
  }

  .block {
    display: flex;
    margin-bottom: 24px;
    font-size: 14px;
    color: #545454;
   
  }

  .block .span{
   
        margin-left: 10px;
      
  }
 

 

  .sidebar-btn-wrapper .sidebar-btn span {
    
      margin-left: 0;
      margin-right: 5px;
    
  }

.badge {
  padding: 3px 6px;
  font-size: 9px;
  letter-spacing: 1px;
  border-radius: 15px;
 
}

.red {
    color: #ffffff;
    background: #d63030;
  }

  
  .gray {
    color: #ffffff;
    background: #777777;
  }
  .yellow {
    color: #000000;
    background: #ffd331;
  }

  .pro-sidebar-inner{
      background: #232323 !important;
      height: 100%;
  }

  .pro-sidebar .footer{
margin: 0;
  }
 .s-icon-times{
  position: absolute;
  top: 0;
    right: 8px;
    margin-top: 10px;
    /* margin-right: 10px; */
    background: white;
    border-radius: 100px;
    display: flex;
    padding: 5px;

}

.pro-sidebar{
 z-index: 9 !important;
}

  @media(max-width: 768px){
    .pro-sidebar{
      position: absolute;
    }
  }

  .s-sidebar .pro-sidebar-inner{
    background: linear-gradient( 
      277.85deg, rgba(41, 0, 78,1) -0.21%, rgba(0, 36, 84,1) 91.73%) !important;
  }

  .s-sidebar__bottomBtn{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1px;
    background: lightblue;
    text-decoration: none;
    color: white;
    /* width: 80%; */
    max-width: 180px;
    border: 3px solid lightblue;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    border-radius: 25px;
    background: linear-gradient(
277.85deg, rgb(10, 23, 39) -0.21%, rgb(0, 36, 84) 91.73%);
  }