.invasion-target {
    min-height: 100px;
    min-width: 100px;
    display: flex;
    vertical-align: middle;
}
.invasion-target img {
    width: 100%;
    height: 100%;
    border-radius: 20px;
    cursor: pointer;
}


.s-starship {
  background: linear-gradient( 
    277.85deg, rgb(10, 23, 39) -0.21%, rgb(0, 36, 84) 91.73%) !important;
}
.s-home__btn--first{
   
    background: linear-gradient(to right, whitesmoke 50%, transparent 50%);
    background-size: 200% 100%;
    background-position: right bottom;
    transition: all .5s ease-out;
}

.s-home__btn--first:hover {
    background-position: left bottom;
  }

  .s-home__btn--first:hover span{
    color: black;
  }
  .s-home__btn--first:hover{
    color: black;
  }

  .s-home__btn--second{
    background: linear-gradient(to right, #181818 50%, whitesmoke 50%);
    background-size: 200% 100%;
    background-position: right bottom;
    transition: all .5s ease-out;
}

.s-home__btn--second:hover {
    background-position: left bottom;

  }

  .s-home__btn--second:hover span{
    color: white;
  }

  .s-home__btn--more{
    text-decoration: none;
    color: black;
    /* width: 99%; */
    max-width: 200px;
    padding: 10px;
    /* height: 100px; */
    border: 2px solid #fff;
    background-color: #fff;
    font-family: Virus_01;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    text-transform: uppercase;
    margin-top: 10px;
    border-radius: 5px;
  }

  .s-home__opensea{
    flex-direction: column !important;
  }
  .s-home__opensea img{
    max-width: 600px;
  }
  .s-home__opensea img:hover{
   
      cursor: pointer;
      transform: scale(1.1, 1.1);
    
    
    
  }

  
  .s-home__opensea img:hover .overlay {
    height: 10%;
  }

  .s-home__opensea img{
    transition: all 0.9s ease-in-out;
    overflow: hidden;
  }
 
  @media(max-width: 768px){
    .s-home__btn--first{
        height: auto;
    }
  }
  

  /* REACT TOGGLE STYLES*/
  .react-toggle {
    touch-action: pan-x;
  
    display: inline-block;
    position: relative;
    cursor: pointer;
    background-color: transparent;
    border: 0;
    padding: 0;
  
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    -webkit-tap-highlight-color: transparent;
  }
  
  .react-toggle-screenreader-only {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  
  .react-toggle--disabled {
    cursor: not-allowed;
    opacity: 0.5;
    -webkit-transition: opacity 0.25s;
    transition: opacity 0.25s;
  }
  
  .react-toggle-track {
    width: 50px;
    height: 24px;
    padding: 0;
    border-radius: 30px;
    background-color: #4D4D4D;
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }
  
  .react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
    background-color: #000000;
  }
  
  .react-toggle--checked .react-toggle-track {
    background-color: #19AB27;
  }
  
  .react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
    background-color: #128D15;
  }
  
  .react-toggle-track-check {
    position: absolute;
    width: 14px;
    height: 10px;
    top: 0px;
    bottom: 0px;
    margin-top: auto;
    margin-bottom: auto;
    line-height: 0;
    left: 8px;
    opacity: 0;
    -webkit-transition: opacity 0.25s ease;
    -moz-transition: opacity 0.25s ease;
    transition: opacity 0.25s ease;
  }
  
  .react-toggle--checked .react-toggle-track-check {
    opacity: 1;
    -webkit-transition: opacity 0.25s ease;
    -moz-transition: opacity 0.25s ease;
    transition: opacity 0.25s ease;
  }
  
  .react-toggle-track-x {
    position: absolute;
    width: 10px;
    height: 10px;
    top: 0px;
    bottom: 0px;
    margin-top: auto;
    margin-bottom: auto;
    line-height: 0;
    right: 10px;
    opacity: 1;
    -webkit-transition: opacity 0.25s ease;
    -moz-transition: opacity 0.25s ease;
    transition: opacity 0.25s ease;
  }
  
  .react-toggle--checked .react-toggle-track-x {
    opacity: 0;
  }
  
  .react-toggle-thumb {
    transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    position: absolute;
    top: 1px;
    left: 1px;
    width: 22px;
    height: 22px;
    border: 1px solid #4D4D4D;
    border-radius: 50%;
    background-color: #FAFAFA;
  
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  
    -webkit-transition: all 0.25s ease;
    -moz-transition: all 0.25s ease;
    transition: all 0.25s ease;
  }
  
  .react-toggle--checked .react-toggle-thumb {
    left: 27px;
    border-color: #19AB27;
  }
  
  .react-toggle--focus .react-toggle-thumb {
    -webkit-box-shadow: 0px 0px 3px 2px #0099E0;
    -moz-box-shadow: 0px 0px 3px 2px #0099E0;
    box-shadow: 0px 0px 2px 3px #0099E0;
  }
  
  .react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
    -webkit-box-shadow: 0px 0px 5px 5px #0099E0;
    -moz-box-shadow: 0px 0px 5px 5px #0099E0;
    box-shadow: 0px 0px 5px 5px #0099E0;
  }

  .s-home__toggle{
    margin-top: 60px;
    text-align: left;
    margin-bottom: 20px;
  display: flex;
  align-items: center;
  }

  .s-home__toggle .s-home__toggle__tooltip svg{
    color: white;
    margin-left: 5px;
  }

  .s-main {
    padding: 24px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
  }

  .s-main-page .crew-members-cont{
    background: #232323;
    margin: 0px;
    padding: 20px;
    border-radius: .3rem;
    box-shadow: none;
  }
  .s-main-page .s-gallery__images--background{
    padding-bottom: 25px;
  }

  @media(max-width: 767px){
    .s-main{
      padding: 10px;
    }
  }
 

  .icon-spin {
    -webkit-animation: icon-spin 2s infinite linear;
            animation: icon-spin 2s infinite linear;
  }
  
  @-webkit-keyframes icon-spin {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(359deg);
              transform: rotate(359deg);
    }
  }
  
  @keyframes icon-spin {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(359deg);
              transform: rotate(359deg);
    }
  }