.invasion-target {
    min-height: 100px;
    min-width: 100px;
    display: flex;
    vertical-align: middle;
}
.invasion-target img {
    width: 100%;
    height: 100%;
}



.s-home__btn--first{
   
    background: transparent;
    background-size: 200% 100%;
    transition: all .5s ease-out;
}

.s-home__btn--first:hover {
   background: whitesmoke;
  }

  .s-home__btn--first:hover span{
    color: black;
  }
  .s-home__btn--first:hover{
    color: black;
  }

  .s-home__btn--second{
    background: whitesmoke;
    background-size: 200% 100%;
    transition: all .5s ease-out;
}

.s-home__btn--second:hover {
    background-position: left bottom;
    background: #181818;
  }

  .s-home__btn--second:hover span{
    color: white;
  }

   .s-home__label{
    text-align: left;
    color: #c7c7c7;
    font-size: 18px;
    font-style: italic;
  }

  @media(max-width: 768px){
    .s-home__btn--first{
        height: auto;

    }


  }