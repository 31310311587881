.modal {
  font-size: 12px;
}
.popup-content {
  background: #282a36;
}
.modal > .header {
  /* width: 100%; */
  border-bottom: 1px solid gray;
  font-size: 18px;
  text-align: center;
  padding: 5px;
  color: #fff;
}

.modal > .content {
  /* width: 100%; */
  padding: 10px 5px;
  color: #fff;
  font-size: 14px;
  text-align: center;
}
.modal > .actions {
  /* width: 100%; */
  padding: 10px 5px;
  margin: auto;
  text-align: center;
}

.modal > .actions button{

    background: whitesmoke;
    background-size: 200% 100%;
    transition: all .5s ease-out;
    width: auto;
    height: 35px;
    border: 2px solid #fff;
    background-color: #fff;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    text-transform: uppercase;
  border-radius: 10px;
    color: #000;
}


.modal > .actions button:hover {
  background-position: left bottom;
  background: #181818;
  color: white;
}

.modal > .actions button:hover span{
  color: white;
}

.modal > .close {
  cursor: pointer;
  position: absolute;
  display: block;
  padding: 2px 5px;
  line-height: 20px;
  right: -10px;
  top: -10px;
  font-size: 24px;
  background: #ffffff;
  border-radius: 18px;
  border: 1px solid #cfcece;
}
/* .example-warper .button,
.popup-content .button {
  background-color: #fff;
  border: 3px dashed #25c2a0;
  border-radius: 8px;
  color: #000;
  cursor: pointer;
  display: inline-block;
  font-family: Roboto, Arial, sans-serif;
  font-size: 14px;
  letter-spacing: 1.5px;
  line-height: 2em;
  margin: 10px 15px;
  outline: 0;
  padding: 0 30px;
  position: relative;
  text-transform: uppercase;
  transition: 0.3s;
} */

.modal > .content {
  
}
.activation_text {
  padding: 5px 0;
}
.activation_image {
  width: 100%;
  max-width: 200px;
  border-radius: 20px;
}




