

.s-link{
    padding: 10px;
    cursor: pointer;
    text-decoration: none;
   max-width: 120px;
   color: white;
  justify-content: center;
   display: flex;
   align-items: center;
   border: 2px solid #fff;
    border-radius: 80px;
   
    background-origin: border-box;
    background-clip: padding-box, border-box;
    background-size: 200% 100%;
    background: transparent;
    background-size: 200% 100%;
    transition: all .5s ease-out;
}


.s-link:hover {
   background: whitesmoke;
  }

  .s-link:hover {
    color: black;
  }





.s-link--activate{
    font-size: 48px;
    text-decoration: underline;
    cursor: pointer;
    line-height: 34px;
    margin: 0 20px;
    scroll-behavior: smooth;
    font-family: pxlxxl;
    font-style: normal;
    font-weight: 400;
    color: #fff;
    transition: all .2s ease-out;
}

.s-link--activate:hover{
    opacity: 0.7;
}

.s-icon {
    display: none;
    color: white;
    font-size: 35px;
}

.s-drawer{
    transition: all 0.5s ease-out;
    outline: 0;
    display: flex;
    flex-direction: column;
    position: absolute;
    max-width: calc(100% - 32px);
    min-width: 16px;
    max-height: calc(100% - 32px);
    min-height: 155px;
  z-index: 9999999;

    top: 0px;
    left: 16px;
    border-radius: 4px;
    background-color: #1e1e1e;
    border: 1px solid #fff;
    box-shadow: 0px 5px 5px -3px rgba(0, 0, 0 , 0.20), 0px 8px 10px 1px rgba(0 ,0, 0 ,0.14), 0px 3px 14px 2px rgba(0, 0, 0,  0.12);

}

.s-drawer a {
    width: auto;
    overflow: hidden;
    font-size: 30px;
    box-sizing: border-box;
    min-height: 48px;
    font-family: pxlxxl;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    padding-top: 6px;
    white-space: nowrap;
    letter-spacing: 0.00938em;
    padding-bottom: 6px;
}

.s-drawer .s-icon{
    font-size: 18px;
    position: absolute;
    right: 0;
    padding: 5px;
}

.s-notification{

    display: none;

    }

    .s-navbar__switchBtn {
        display: flex;
        align-items: center;
    }
    .s-navbar__switchBtn p{
       color: white;
       font-size: 48px;
      
       cursor: pointer;
       line-height: 34px;
       margin: 0 20px;
       scroll-behavior: smooth;
       font-family: pxlxxl;
       font-style: normal;
       font-weight: 400;
       color: #fff;
       transition: all .2s ease-out;
    }

@media(max-width: 768px){
    .hidden{
        display: none
    }
    .s-link--activate{
        line-height: 0px;
        margin-top: 10px;
        font-size: 25px;
    }

    .s-navbar__switchBtn{
        display: none;
    }

    .navbar .s-link{
        padding: 5px;
        max-width: 66px;
        margin-right: 30px;
        margin: 0;
    margin-right: -10px;
    }

    .s-icon{
        display: flex
    }

    .s-notification{
        position: absolute;
        top: 34px;
        border-radius: 4px;
        background-color: #5f5f5f;
        border: 1px solid #fff;
        box-shadow: 0px 5px 5px -3px rgba(0, 0, 0 , 0.20), 0px 8px 10px 1px rgba(0 ,0, 0 ,0.14), 0px 3px 14px 2px rgba(0, 0, 0,  0.12);
        padding: 10px;
        color: white;
        font-size: 14px;
        display: flex;
        z-index: 999999999;
        margin-top: -35px;
    }
}

